// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Stimulus controllers
import { application } from './controllers/application'

import PhotoBlockController from './controllers/photo_block_controller'
application.register('photo-block', PhotoBlockController)

import ReviewsController from './controllers/reviews_controller'
application.register('reviews', ReviewsController)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.jQuery = $;
window.$ = $;
