import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'buttonOpen', 'buttonClose', 'otherPhotos', 'presenter', 'slides' ]

  currentSlide

  showOther() {
    this.buttonOpenTarget.classList.add('hidden')
    this.buttonCloseTarget.classList.remove('hidden')
    this.otherPhotosTarget.classList.remove('hidden')
  }

  hideOther() {
    this.buttonCloseTarget.classList.add('hidden')
    this.buttonOpenTarget.classList.remove('hidden')
    this.otherPhotosTarget.classList.add('hidden')
  }

  showPresenter({ params: { id } }) {
    this.presenterTarget.style.display = 'block'

    this.currentSlide = id
    this.showSlide()
  }

  showSlide() {
    this.slidesTargets.forEach((slide) => {
      slide.style.display = 'none'
    })

    this.slidesTargets[this.currentSlide].style.display = 'block'
  }

  hidePresenter() {
    this.presenterTarget.style.display = 'none'
  }

  slideNext() {
    this.currentSlide += 1

    if (this.currentSlide >= this.slidesTargets.length) {
      this.currentSlide = 0
    }

    this.showSlide()
  }

  slidePrev() {
    this.currentSlide -= 1

    if (this.currentSlide < 0) {
      this.currentSlide = this.slidesTargets.length - 1
    }

    this.showSlide()
  }
}
