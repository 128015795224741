import { Controller } from 'stimulus'
import ky from 'ky'
import { template } from 'lodash'

export default class extends Controller {
  static targets = [ 'list', 'modal', 'template', 'form', 'thanks', 'text', 'name', 'phone', 'email', 'image' ]

  currentPage = 0
  limit = false

  async connect () {
    this.reviewTemplate = template(this.templateTarget.innerHTML)

    await this.getReviews()
  }

  more () {
    this.getReviews()
  }

  open (e) {
    e.stopPropagation()
    this.modalTarget.classList.remove('hidden')
  }

  close (e) {
    e.stopPropagation()
    this.modalTarget.classList.add('hidden')
  }

  async submit (e) {
    e.preventDefault()

    try {
      await this.pushReview()
      this.formTarget.classList.add('hidden')
      this.thanksTarget.classList.remove('hidden')
    } catch (e) {
      if (!(e instanceof ky.HTTPError)) throw e
    }
  }

  async getReviews() {
    if (this.limit) { return }

    this.currentPage += 1

    const reviews = await ky.get(this.pageUrl()).json()

    if (reviews.length < 10) {
      this.limit = true
    }

    for (const [key, review] of Object.entries(reviews)) {
      this.listTarget.insertAdjacentHTML(
        'beforeend',
        this.reviewTemplate({ url: review[0], name: review[1], date: review[2], text: review[3] })
      )
    }

    // url << /reviews/page/${this.currentPage}
  }

  pageUrl () {
    return '/reviews/page/' + this.currentPage
  }

  async pushReview () {
    let body = new FormData()
    body.append('fio', this.reviewName)
    body.append('text', this.reviewText)
    body.append('phone', this.reviewPhone)
    body.append('email', this.reviewEmail)
    body.append('image', this.reviewImage)

    return await ky.post('/reviews/', { body: body })
  }

  kyOptionsAPI (json = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.csrfToken
    }
    return { headers: headers, json: json }
  }

  get csrfToken () {
    return $('meta[name=csrf-token]')?.content
  }

  get reviewText () { return this.textTarget.value }

  get reviewName () { return this.nameTarget.value }

  get reviewPhone () { return this.phoneTarget.value }

  get reviewEmail () { return this.emailTarget.value }

  get reviewImage () { return this.imageTarget.files[0] }
}
